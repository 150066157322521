$menuTogglerLineSize: 1px;
$menuTogglerLineSpace: 3px;

.menuToggler {
	@mixin line() {
		backface-visibility: hidden;
		background-color: currentColor;
		height: $menuTogglerLineSize;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		@include ease(transform, $duration2);
	}
	&:focus, &:hover {
		color: black
	}
	color: $colorHighlight;

	&__label {
		@include line();
		@include ease(background-color, $duration2);
		font-size: 0;
		text-indent: -10000px;
		transform: translateY(#{$menuTogglerLineSpace + $menuTogglerLineSize});

		.menuToggler.js-toggled &,
		.menuToggler--close & {
			background-color: transparent;
		}

		&::before {
			@include line();
			@include ease(transform, $duration2);
			content: '';
			transform: translateY(-#{$menuTogglerLineSpace + $menuTogglerLineSize});

			.menuToggler.js-toggled &,
			.menuToggler--close & {
				transform: translateY(0) rotate(45deg);
			}
		}


		&::after {
			@include line();
			@include ease(transform, $duration2);
			content: '';
			transform: translateY(#{($menuTogglerLineSpace + $menuTogglerLineSize)});

			.menuToggler.js-toggled &,
			.menuToggler--close & {
				transform: translateY(0) rotate(-45deg);
			}
		}

		.menuToggler:not(.js-toggled):not(.menuToggler--close):hover &,
		.menuToggler:not(.js-toggled):not(.menuToggler--close):focus & {
			&::before {
				transform: translateY(-#{$menuTogglerLineSpace * 3 + $menuTogglerLineSize});
			}

			&::after {
				transform: translateY(#{$menuTogglerLineSpace * 3 + $menuTogglerLineSize});
			}
		}
	}
}
