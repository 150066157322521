.header {
	@include app-container;
	display: flex;
	align-items: center;
	top: $gridRow;
	margin: $gridRow 0;
	padding: 0 $mobilePadding;

	&__menu {
		line-height: 0;
	}

	&__menuToggler {
		order: 0;
		width: $gridUnit * 2;
		height: $gridUnit;
		position: relative;
		top: 1px;
		cursor: pointer;
		line-height: 0;
		@include media($midBreakpoint) {
			display: none;
		}
	}

	&__siteLogo {
		order: 2;
		@include media($midBreakpoint) {
			width: gridColumns(2, 12);
			order: 1;
		}
	}

	&__title {
		font-weight: normal;
		@include font(large);
		min-width: $base * 25;
		display: none;
		@include media($midBreakpoint) {
			display: block;
			white-space: normal;
			min-width: auto;
		}
	}

	&__homeLink {
		@include app-link($colorBgAlternative);
	}

	&__line {
		border-bottom: solid 1px $colorBgAlternative;
		margin: 0 $gridUnit;
		width: 100%;
		order: 1;
		@include media($midBreakpoint) {
			margin: 0;
			width: gridColumns(7, 12);
			order: 2;
		}
	}

	&__logo {
		order: 3;
		display: block;
		//height: auto;
		padding-left: $gridUnit * 2;
		padding-right: $gridUnit;
		min-width: $base * 15;
		// @include media($midBreakpoint) {
		// 	order: 3;
		// 	display: block;
		// 	height: auto;
		// 	//width: gridColumns(3, 12);
		// 	padding-left: $gridUnit * 3;
		// }
		@include media($midBreakpoint) {
			min-width: $base * 20;
		}
	}

	&__logoImg {
		@include media($midBreakpoint) {
			min-height: $gridRow * 2;
		}
	}

	@include media($midBreakpoint) {
		padding: 0;
		margin: $gridRow auto;
		width: gridColumns(12);
		height: $gridRow * 2;
		&__humboldtLink {
			[data-type="svg"] {
				display: inline-block;
				max-height: $gridRow * 2;
			}
		}
	}

	height: $gridUnit * 2;
	transition: height 0.4s, margin 0.4s, opacity 0.4s;
	opacity: 1;
	&.js-coveredByCollage {
		opacity: 0;
		height: 0;
		margin-bottom: $gridRow;
		margin-top: 0;
	}
}
