.projectTeaser {
	display: block;
	position: relative;
	z-index: 1;
	background-color: $colorBgAlternativeTrans;
	@include app-link();
	cursor: pointer;

	&--noLink {
		cursor: default;
	}

	&:before {
		content: ' ';
		display: block;
		height: pxToRem(10);
		transition: background-color 0.2s;
	}
	&--special {
		background: none;
		z-index: 0;
		&:before {
			background-color: $colorHighlight;
		}
	}
	&__content {
		padding: pxToRem(12) pxToRem(10) pxToRem(22) pxToRem(10);
	}
	&__image {
		margin-top: $gridUnit;
		overflow: hidden;
		img {
			max-width: 100%;
		}
	}
	&__title {
		@include font(large);
		margin-bottom: $gridUnit;
	}
	&__footer {
		@include font(metaBase);
		font-family: $fontFamilyAlt;
		border-top: 1px solid transparentize($colorBase, 0.8);
		padding-top: $gridUnit;
		margin-top: $gridUnit;
		transition: border-color 0.2s;
	}
	&__caption {
		@include font(small);
		font-family: $fontFamilyAlt;
		line-height: 1.4rem;
	}
	&__collageImage {
		width: 100%;
		height: 0;
		padding-bottom: 100%; // aspect ratio, set inline
		position: relative;
		img {
			width: 100%;
			height: auto;
			position: absolute;
			max-width: 100%;
		}
		@include media($midBreakpoint) {
			img {
				max-width: 140%;
			}
			transform: translateX(-20%);
		}
		z-index: -1;
	}
	&:not(.projectTeaser--noLink):hover {
		background:none;
		z-index: 2;
		&:before {
			background-color: $colorHighlight;
		}
	}
	&:hover &__footer {
		border-color: $colorHighlight;
	}

	.collage & {
		background-color: $colorHighlight;
		color: white;
		&__footer {
			border-color: white;
		}
	}
	.collage &:hover &__footer {
		border-color: white;
	}

	&__close {
		position: absolute;
		top: $mobilePadding;
		right: $mobilePadding;
		z-index: 1;
		cursor: pointer;
		@include media($midBreakpoint) {
			display: none;
		}
	}
}
