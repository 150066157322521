$basicBorder: solid 1px transparentize($colorBase, 0.7);
$pad: $gridUnit * 2;
$valueInset: $gridUnit;

@mixin optionBox {
	height: $gridRow;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.select {
	font-family: $fontFamilyAlt;
	@include font(metaBase);
	position: relative;
	@include media($midBreakpoint) {
		@include font(metaBigger);
	}

	&__value {
		@include optionBox;
		line-height: 1.4;
		border: $basicBorder;
		cursor: pointer;
		user-select: none;
		position: relative;
		padding: 0 $pad;
 		z-index: 2;
		.js-open & {
			padding: 0;
			background-color: $colorBgAlternative2;
			border: none;
			height: $gridRow + $valueInset;
			@include media($midBreakpoint) {
				height: $gridRow;
				padding: 0 $pad;
			}
		}
		&:focus {
			outline: none;
		}
		// the triangle
		&:after {
			content: '';
			position: absolute;
			margin: $pad 0;
			top: -0.5rem;
			right: $pad;
			width: 0;
			height: 0;
			border-left: 1rem solid transparent;
			border-right: 1rem solid transparent;
			border-top: 1rem solid $colorHighlight;
			.js-open & {
				top: $gridUnit - 0.5rem;
				@include media($midBreakpoint) {
					top: -0.5rem;
				}
			}
			@include media($midBreakpoint) {
				top: -0.5rem;
				right: $gridUnit * 1.5;
				transition: all 0.2s;
			}
		}
	}

	&__valueLabel {
		@include optionBox;
		text-align: left;
		&:hover {
			color: $colorHighlight;
		}
		&:focus {
			color: $colorHighlight;
			text-decoration: underline;
		}
		.js-open & {
			border: $basicBorder;
			margin: $valueInset;
			margin-bottom: 0;
			padding: $valueInset;
			@include media($midBreakpoint) {
				border: none;
				margin: 0;
				padding: 0;
				border-bottom: $basicBorder;
			}
		}
	}

	&__options {
		opacity: 0;
		visibility: hidden;
		position: absolute;
		background-color: $colorBgAlternative2;
		width: 100%;
		z-index: 10;
		left: 0;
		padding: 0 $pad;
		padding-bottom: $gridRow;
		@include media($midBreakpoint) {
			transition: opacity 0.3s;
		}
	}

	// background overlay
	&:before {
		opacity: 0;
		visibility: hidden;
		content: ' ';
		pointer-events: none;
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1;
		background-color: transparentize($colorBase, 0.7);
		@include media($midBreakpoint) {
			transition: opacity 0.6s;
		}
	}

	&.js-open {
		position: fixed;
		z-index: 3;
		top: $mobilePadding;
		left: $mobilePadding;
		width: calc(100% - (#{$mobilePadding} * 2));
		height: calc(100% - (#{$mobilePadding} * 2));
		background-color: $colorBgAlternative2;
		@include media($midBreakpoint) {
			position: relative;
			top: 0;
			left: 0;
			width: auto;
			height: auto;
		}
	}


	&__option {
		height: $gridRow;
		display: flex;
		width: 100%;
		position: relative;
		z-index: 3;
		border-bottom: $basicBorder;
		a {
			@include app-link;
		}
		&:hover {
			color: $colorHighlight;
			border-color: $colorHighlight;
		}
		&:focus {
			color: $colorHighlight;
			border-color: $colorHighlight;
		}
		&--selected {
			border-color: $colorHighlight;
		}
		&--disabled {
			opacity: 0.3;
			pointer-events: none;
		}
	}

	&__optionLink {
		display: flex;
		height: $gridRow;
		align-items: center;
		width: 100%;
		&:hover {
			color: $colorHighlight;
		}
		&:focus {
			outline: none;
			color: $colorHighlight;
		}
	}

	&__optionLabel {
		width: 100%;
	}

	&__optionCount {
		margin-left: auto;
	}


	&:not(.js-mounted):hover:before, &.js-open:before,
	&:not(.js-mounted):hover &__options, &.js-open &__options,
	{
		opacity: 1;
		visibility: visible;
	}

	&:not(.js-mounted):hover &__value:after, &.js-open &__value:after
	{
		transform: rotate(180deg);
	}


}
