.sharePopup {
	background-color: $colorHighlight;
	color: white;
	font-family: $fontFamilyAlt;
	@include font(metaBase);
	display: none;
	text-align: left;
	z-index: 20;

	$pad: $gridUnit * 2;
	position: fixed;
	width: 100vw;
	left: 0;
	top: 0;
	padding: $mobilePadding;

	@include media($midBreakpoint) {
		position: absolute;
		margin: auto;
		left: auto;
		right: -1px;
		width: 100vw;
		max-width: ((100vw/14) * 4);
		padding: $pad;
	}

	&:focus {
		border: none;
		outline: none;
	}
	&.js-show {
		display: block;
	}
	&__title {
		@include meta-header;
		margin-bottom: $gridUnit;
	}
	&__text {
		margin-bottom: $gridUnit;
	}
	&__close {
		position: absolute;
		top: $pad;
		right: $pad;
		cursor: pointer;
	}
	&__form {
		display: flex;
	}
	&__url {
		width: 100%;
		color: inherit;
		appearance: none;
		border: 0;
		background-color: transparent;
		border-bottom: solid 1px white;
	}
	&__copyButton {
		margin-left: $gridUnit;
	}
}
