.filters {
	display: flex;
	flex-direction: column;
	&__theme {
		margin-bottom: $gridUnit * 2;
	}
	@include media($midBreakpoint) {
		flex-direction: row;
		&__theme, &__activity {
			background-color: $colorBgBase;
			margin-bottom: 0;
			width: 45%;
		}
		&__line {
			border-top: 1px solid $colorHighlight;
			width: 10%;
			height: 1px;
			margin: $gridRow / 2;
		}
	}
	&__anchor {
		@include media($midBreakpoint) {
			margin-top: - $gridUnit;
		}
	}
}

