.prelaunch {
	&__stamp {
		display: none;
		width: 200px;
		border: 3px solid red;
		text-align: center;
		border-radius: 13px;
		z-index: 99;
		position: absolute;
		top: 80px;
		color: red;
		font-weight: bold;
		font-family: monospace;
		font-size: 27px;
		transform: rotate(-45deg);
	}
}

.js-pageBahnhof {
	// hack some links away
	// server does redirect
	.mainMenu {
		.prelaunch__stamp {
			display: block;
		}
		.menu__item {
			opacity: 0.5;
			pointer-events: none;
		}
	}
	.toolboxButton--close {
		display: none;
	}
	.header__humboldtLink,
	.header__homeLink {
		pointer-events: none;
	}
}

