.sidebar {
	.js-covered & {
		.ohu-blocks {
			display: none;
		}
	}
	order: 3;
	@include media($sidebarBreakpoint) {
		z-index: 2; // needs to go “over” main content, and “over” media modal
		order: 3;
		position: absolute;
		width: gridColumns(3, 11);
		top: 0;
		right: 0;
	}
}
