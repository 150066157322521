.contact {
	margin-bottom: $gridRow;
	&__portrait {
		max-width: 100%;
		margin-bottom: $gridUnit * 2;
	}
	&__label, &__address, &__connections {
		margin-bottom: $gridUnit * 2;
	}
	&__address, &__connections {
		font-family: $fontFamilyAlt;
		@include font(metaBigger);
		a {
			@include app-link()
		}
	}
	&__textLabel {
		&:after {
			display: inline;
			content: ' •';
			color: $colorHighlight;
		}
		@include media($midBreakpoint) {
			display: none;
		}
	}
	&__textLabel + * {
		display: inline;
	}
	@include media($smallBreakpoint) {
		width: gridColumns(5, 10);
		padding-right: $gridUnit * 2;
	}
	@include media($largeBreakpoint) {
		width: gridColumns(3, 10);
	}
}
