.specialQuoteHeader {
	margin-bottom: $gridRow * 2;
	position: relative;
	transition: margin-top 0.4s;
	margin-top: 0 -  ($gridRow * 2);
	.js-coveredByCollage & {
			margin-top: 0 -  ($gridRow);
	}

	&__quote {
		@include font(quote);
		line-height: 1.5;
		padding: 0 $mobilePadding;
		margin: 0;
		@include media($midBreakpoint) {
			text-align: justify;
			padding: 0;
			margin-left: gridColumns(3);
			width: gridColumns(8, 14);
		}
		@include media($sidebarBreakpoint) {
			margin-left: gridColumns(4.5);
			width: gridColumns(5, 14);
		}
	}
	&__link {
		@include app-link();
		position: absolute;
		right: 0;
		display: flex;
		align-items: center;
		bottom: 0 - ($gridUnit * 3);
		&:after {
			content: ' ';
			display: inline-block;
			height: 1px;
			width: 7.14vw;
			border-bottom: 1px solid $colorHighlight;
			margin-left: $gridUnit;
		}

		@include media($sidebarBreakpoint) {
			bottom: 0;
		}
	}
}
