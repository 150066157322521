.block {

	display: flex;
	flex-direction: row;
	position: relative;

	&--title {
		padding-top: $gridRow;
		//hyphens: auto;
		//@include media($columnsBreakpoint) {
		//	hyphens: unset;
		//}
	}

	&--title, &--contacts {
		padding-left: $mobilePadding;
		padding-right: $mobilePadding;
		@include media($midBreakpoint) {
			padding-left: gridColumns(1, 11);
			padding-right: gridColumns(1, 11);
			.sidebarredContainer & {
				padding-left: gridColumns(1, 8);
				padding-right: gridColumns(1, 8);
			}
		}
	}

	&__container {
		width: 100%;
	}
	.ohu-js-covered &--callToActionTeaser {
		display: none;
	}

}
