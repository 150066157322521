.categoryList {
	@include font(metaBigger);
	font-family: $fontFamilyAlt;
	display: block;
	&__category {
		display: inline-block;
		opacity: 0.5;
		&--theme + &--activity {
			&:before {
				content: ' ';
				display: inline-block;
				position: relative;
				margin-right: $gridUnit;
				width: $gridUnit * 2;
				top: -0.3rem;
				border-bottom: 1px solid $colorHighlight;
			}
		}
		& + & {
			margin-left: $gridUnit;
		}
		a {
			@include app-link();
		}
	}

	.page--home & {
		margin-bottom: $gridUnit;
		@include font(metaBase);
		&__category {
			opacity: 1;
		}
	}

	.sidebarredContainer & {
		padding-left: $mobilePadding;
		padding-right: $mobilePadding;
		@include media($midBreakpoint) {
			padding-left: gridColumns(1, 8);
			padding-top: $gridUnit * 2;
		}
	}

	.collage & {
		@include font(metaBase);
		&__category {
			opacity: 1;
		}
	}



}
