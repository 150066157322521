.projectGridContainer {
	padding: 0 $mobilePadding;
	top:0;
	position: relative;
	&__header {
		@include media($sidebarBreakpoint) {
			position: sticky;
			top:$gridUnit;
			z-index: 3;
		}
		margin-bottom: $gridUnit;

		.safari & {
			// no stickiness on safari, adjust layout a bit
			margin-bottom: $gridUnit * 2;
		}
	}
	@include media($midBreakpoint) {
		padding: 0;
	}
}
