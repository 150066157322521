.galleryTeaser {

	padding-bottom: $gridRow;

	@include media($sidebarBreakpoint) {
		padding-bottom: 0;
		border-top: 1px solid $colorHighlight;
	}

	&__main {
		@include media($sidebarBreakpoint) {
			padding-bottom: $gridRow;
		}
	}
	&__img {
		text-align: left;
		cursor: pointer;
		width:100%;
		position: relative;
		@include media($sidebarBreakpoint) {
			padding: 0 gridColumns(1, 8);
		}
	}

	&__meta {
		text-align: center;
		margin-top: $gridUnit * 2;
		@include media($sidebarBreakpoint) {
			margin-top: 0;
			padding: $gridRow gridColumns(0.5, 12);
		}
	}

	&__title {
		display: none;
		@include media($sidebarBreakpoint) {
			display: block;
			@include meta-header;
			padding-bottom: $gridRow;
		}
	}

	&__text {
		display: none;
		@include media($sidebarBreakpoint) {
			display: block;
			margin-bottom: $gridRow;
		}
	}

	&__trigger {
		cursor: pointer;
		text-decoration: underline;
		text-decoration-color: $colorHighlight;
		[data-type="svg"] {
			display: none;
			@include media($sidebarBreakpoint) {
				display: inline-block;
			}
		}
		@include onHover() {
			cursor: pointer;
			color: $colorHighlight;
		}
	}

	&__mobileIcon {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		width: $gridUnit * 6;
		height: $gridUnit * 6;
		&:before {
			@include app-circle($gridUnit * 6);
		}
		&:hover:before {
			mix-blend-mode: normal;
		}
		[data-type="svg"] {
			color: white;
		}
		@include media($sidebarBreakpoint) {
			display: none;
		}
	}

	& .image__imgWrapper {
		position: relative;
	}

	& .image__caption {
		padding-left: $mobilePadding;
		padding-right: $mobilePadding;
	}

}
