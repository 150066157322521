.eventList {
	order:4;
	padding-bottom: $gridRow * 2;
	padding-left: $mobilePadding;
	padding-right: $mobilePadding;
	@include media($midBreakpoint) {
		padding-left: gridColumns(1, 11);
	}
	&__header {
		margin-bottom: $gridUnit * 2;
		@include media($midBreakpoint) {
			margin-bottom: $gridRow;
			padding-right: gridColumns(1, 10);
		}
	}
	&__title {
		@include font(largeBreathing);
		margin-bottom: $gridUnit * 2;
	}
	&__core {
		width: 100%;
		@include media($midBreakpoint) {
			padding-right: gridColumns(1, 10);
		}
	}
	.page--project & {
		margin-top: $gridRow * 2;
		margin-bottom: $gridRow * 2;
	}
	.page--events & {
		@include media($midBreakpoint) {
			margin-top: $gridRow;
		}
	}
}
