.collage {

	$offStageMobile: 140%;
	$offStageMid: 60%;
	$offStageDesktop: 5%;

	margin: auto;
	max-width: $containerMaxWidth;
	position: relative;
	// we need the padding otherwise the labels at the bottom of the collage do not fire mouse move events to scroll the collage
	padding-bottom: $gridRow * 2;
	left: 0;
	&:focus {
		outline: none;
	}

	&__main {
		position: relative;
		transition: margin-left 0.2s;
		left: 0 - $offStageMobile;
		margin-bottom: $gridRow * 2;
		@include media($midBreakpoint) {
			left: 0 - $offStageMid;
		}
		@include media($sidebarBreakpoint) {
			left: 0 - ($offStageDesktop * 2);
			max-width: calc(#{$offStageDesktop * 2} + #{$containerMaxWidth});
			margin-bottom: $offStageDesktop;
		}
	}

	&__sprite {
		position: absolute;

		img {
			width: 100%;
			object-fit: fill;
		}
	}
	&__img {
		@include fadeOut();
		@include ease(visibility opacity, 0.5s);

		&.js-loaded {
			@include fadeIn();
		}
	}

	&__project {
		position: absolute;
		opacity: 0;
		visibility: hidden;
		transition: opacity 0.2s, margin-left 0.4s;
		z-index: 1;
		cursor: pointer;
		margin-left: 0;
		&:hover {
			z-index: 2;
		}

		&:before {
			bottom: 0;
			left: 0;
			border-left: solid 1px;
			border-bottom: solid 1px;
		}

		&:after {
			top: 0;
			right: 0;
			border-right: solid 1px;
			border-top: solid 1px;
		}

		&:before, &:after {
			content: '';
			position: absolute;
			display: block;
			width: 100px;
			height: 100px;
			border-color: $colorHighlight;
		}

		&.js-panOver {
			background-color: transparentize($colorHighlight, 0.8);
			mix-blend-mode: multiply;
			//background-color: $colorHighlight;
		}
		&.js-show {
			background-color: unset;
			mix-blend-mode: unset;
		}

		.js-open & {
			visibility: visible;
			opacity: 1;
		}

		@include media($sidebarBreakpoint) {
			height: auto !important;
		}
	}

	&__teaser {
		visibility: hidden;
		opacity: 0;
		transition: 0.4s opacity;
		position: relative;
		.js-show & {
			opacity: 1;
			visibility: visible;
			z-index: 1;
		}

	}

	&__label {
		opacity: 0;
		visibility: hidden;
		transition: opacity 0.2s;
		position: absolute;
		display: flex;
		flex-direction: column;
		border-left: solid 1px $colorHighlight;
		width: $gridUnit * 8;
		.js-open & {
			visibility: visible;
			opacity: 1;
		}
		.js-showProject & {
			display: none;
		}
	}

	&__lineLabel {
		margin-top: auto;
		font-family: $fontFamilyAlt;
		@include font(metaBigger);
		color: $colorHighlight;
		padding-left: $gridUnit;
	}

	&__close {
		position: absolute;
		z-index: 10;
		visibility: hidden;
		right: 5%;
		top: 5%;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		$diameter: $gridUnit * 4;
		width: $diameter;
		height: $diameter;
		&:before {
			@include app-circle($diameter);
		}
		@include media($sidebarBreakpoint) {
			$diameter: $gridRow * 2;
			width: $diameter;
			height: $diameter;
			&:before {
				@include app-circle($diameter);
			}
		}
		[data-type="svg"] {
			color: white;
			width: 50%;
			@include media($sidebarBreakpoint) {
				width: auto;
			}
		}
		&:hover:before {
			background-color: $colorHighlightHover;
		}
		.js-open & {
			visibility: visible;
		}
		.js-showProject & {
			visibility: hidden;
		}
	}

	$openerDiameter: $gridRow * 2.25;
	$fullOpenerDiameter: $gridRow * 3.5;

	&__opener {
		position: absolute;
		visibility: hidden;
		bottom: -10%;
		width: $openerDiameter;
		height: $openerDiameter;
		left: 0;
		right: 0;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		top: 35%;
		@include media($sidebarBreakpoint) {
			width: $fullOpenerDiameter;
			height: $fullOpenerDiameter;
		}

		&:after {
			@include app-circle($openerDiameter);
			@include media($sidebarBreakpoint) {
				@include app-circle($fullOpenerDiameter);
			}
		}

		&:hover:after {
			transition: background-color 0.4s;
			background-color: $colorHighlightHover;
		}

		.js-ready & {
			visibility: visible;
		}

		.js-open & {
			&:after {
				background-color: transparent;
			}
		}

	}

	&__openerLabel {
		display: inline-block;
		color: white;
		position: relative;
		z-index: 1;
		transition: opacity 0.4s;
		width: $openerDiameter;
		height: $openerDiameter;
		line-height: $openerDiameter; // we need to make label big because of issue with mix-blend-mode and z-index on background
		@include media($sidebarBreakpoint) {
			font-size: pxToRem(28px);
			width: $fullOpenerDiameter;
			height: $fullOpenerDiameter;
			line-height: $fullOpenerDiameter;
		}

		.js-open & {
			opacity: 0;
			visibility: hidden;
		}

		transform: scale(1);
		animation: pulse 2s infinite;
	}

	@keyframes pulse {
		0% {
			transform: scale(0.9);
		}

		30% {
			transform: scale(1);
		}

		100% {
			transform: scale(0.9);
		}
	}

	&__headline {
		position: absolute;
		bottom: ($gridRow * 2) + $gridUnit;
		left: $mobilePadding;
		visibility: visible;
		font-size: pxToRem(28px);
		color: $colorBgAlternative;
		line-height: 1;
		z-index: 1;
		@include media($midBreakpoint) {
			text-align: center;
			position: absolute;
			margin: 0 auto;
			top: 10%;
			left: 0;
			right: 0;
			font-size: pxToRem(56px);
			width: gridColumns(9, 11);
		}

		.js-open & {
			visibility: hidden;
		}
	}

	&__headlineTerm1 {
		display: block;
		@include media($midBreakpoint) {
			display: inline;
		}
	}

	&__headlineSeparator {
		color: $colorHighlight;

		[data-type=svg] {
			width: pxToRem(18px);
			@include media($midBreakpoint) {
				width: pxToRem(42px);
			}
		}

		@include media($midBreakpoint) {
			margin: 0 ($gridUnit / 2);
		}
	}

	&__slotWrapper {
		width: 100%;
		overflow-x: hidden;
		@include media($midBreakpoint) {
			// overflow-x: unset;
		}
		&.js-coveredByMenu {
			@include app-covered();
			display: none;
		}
	}
}
