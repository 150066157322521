.ctaTeaser {
	display: none;
	@include media($sidebarBreakpoint) {
		@include font(metaBigger);
		font-family: $fontFamilyAlt;
		display: block;
		position: relative;
		cursor: pointer;
		margin-bottom: $gridRow;
		text-decoration: none;
	}

	&:hover &__label, &:hover &__tail:after {
		color: white;
		background-color: $colorHighlight;
	}

	&__label {
		color: $colorHighlight;
		$size: pxToRem(130);
		border: solid 1px $colorHighlight;
		transition: background-color 0.4s;
		width: $size;
		height: $size;
		border-radius: $size / 2;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
	}

	&__tail {
		position: relative;
		height: $gridRow * 3;
		width: 1px;
		border-left: solid 1px $colorHighlight;
		margin: 0 auto;
		top: 0 - ($gridUnit * 2);

		&:after {
			$tipSize: $gridUnit;
			content: ' ';
			position: absolute;
			transition: background-color 0.4s;
			bottom: 0 - ($tipSize / 2);
			left: 0 - ($tipSize / 2);
			margin: 0 auto;
			width: $tipSize;
			height: $tipSize;
			border-radius: $tipSize / 2;
			border: solid 1px $colorHighlight;
		}
	}

}
