// todo combine this with gallery-teaser
.videoTeaser {

	padding-bottom: $gridRow;

	@include media($sidebarBreakpoint) {
		padding-bottom: 0;
		border-top: 1px solid $colorHighlight;
	}

	&__main {
		@include media($sidebarBreakpoint) {
			padding-bottom: $gridRow;
		}
	}

	&__img {
		display: block;
		width: 100%;
		height: 100%;
		text-align: left;
		cursor: pointer;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		iframe {
			z-index: 1;
		}
	}
	&__meta {
		text-align: center;
		margin-top: $gridUnit * 2;
		@include media($sidebarBreakpoint) {
			margin-top: 0;
			padding: $gridRow gridColumns(0.5, 12);
		}
	}
	&__title {
		display: none;
		@include media($sidebarBreakpoint) {
			display: block;
			@include meta-header;
			padding-bottom: $gridRow;
		}
	}
	&__text {
		display: none;
		@include media($sidebarBreakpoint) {
			display: block;
			margin-bottom: $gridRow;
		}
	}
	&__trigger {
		cursor: pointer;
		text-decoration: underline;
		text-decoration-color: $colorHighlight;
		[data-type="svg"] {
			display: none;
			@include media($sidebarBreakpoint) {
				display: inline-block;
			}
		}


	}
}
