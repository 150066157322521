.toolboxButton {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	width: $gridUnit * 4;
	height: $gridUnit * 4;
	@include media($sidebarBreakpoint) {
		width: $gridUnit * 3;
		height: $gridUnit * 3;
		background-color: unset;
	}
	@include media($largeBreakpoint) {
		width: $gridUnit * 4;
		height: $gridUnit * 4;
	}
	text-align: center;
	position: relative;

	&.js-active {
		color: $colorHighlight;
		pointer-events: none;
	}

	@include onHover() {
		cursor: pointer;
		color: $colorHighlight;
	}
	@include onHover($applyOn: ' #{&}__tooltip') {
		display: block;
	}

	&__tooltip {
		$offset: $gridUnit * 6;
		display: none;
		position: absolute;
		color: white;
		background: $colorHighlight;
		text-align: center;
		white-space: nowrap;
		z-index: 50;
		top: 0 - $gridUnit;
		right: 0;
		margin-top: $offset;
		margin-right: -1px;
		font-family: $fontFamilyAlt;
		@include font(metaBigger);
		padding: ($gridUnit / 4) $gridUnit;

		&:after {
			content: "";
			position: absolute;
			right: 0;
			top: 0 - $offset;
			height: $offset;
			border-right: 1px solid $colorHighlight;
		}
	}

	.js-coveredByMedia &__tooltip {
		top: 0 - ($gridUnit * 2);
	}
	&--close {
		display: none;
		background: $colorHighlight;
		color: white;
		border-radius: 50%;
		@include onHover() {
			color: white;
		}
	}
	.js-coveredByMedia &--close {
		display: flex;
	}
	.js-coveredByMedia &--gallery,
	.js-coveredByMedia &--share,
	.js-coveredByMedia &--text,
	.js-coveredByMedia &--play {
			display: none;
	}
}
